<template>
  <div>
    <!-- table-box -->
    <div>
      <div class="rtl-right search-box">
        <el-row :gutter="5">
          <el-col :span="5">
            <el-select v-model="filterForm.teacher_id" class="rtl-right" @change="toSearch()" clearable placeholder="ئوقۇتقۇچى">
              <el-option
                v-for="item in teachersData"
                :key="item.id"
                :label="item.name_ug"
                :value="item.id">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="5">
            <el-input v-model="filterForm.keyword" @keyup.native.enter="toSearch()" clearable placeholder="ھالقىلىق سۆزنى كىرگۈزۈڭ"></el-input>
          </el-col>
          <el-col :span="7">
          <el-date-picker
            style="width:100%"
            class="ltr-left"
            v-model="filterForm.timeRange"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="-"
            unlink-panels
            @change="dateTimeChage()"
            start-placeholder="باشلىنىش ۋاقتى"
            end-placeholder="ئاخىرلىشىش ۋاقتى">
          </el-date-picker>
        </el-col>
          <el-col :span="4">
            <el-button type="success" plain icon="el-icon-search" @click="toSearch()">ئىزدەش </el-button>
            <el-button type="danger" plain icon="el-icon-circle-close" @click="toClear()">بىكار  قىلىش </el-button>
          </el-col>
        </el-row>
      </div>

      <div class="table-box">
        <el-table
          :data="tableData" 
          border 
          style="width: 100%" 
          v-loading="loading">
          <el-table-column type="index" :index="indexMethod" label="#"></el-table-column>
          <el-table-column prop="uid" label="ID  نۇمىرى"></el-table-column>
          <el-table-column prop="teacher_name_ug" label="ئوقۇتقۇچى ئىسمى"></el-table-column>
          <el-table-column prop="sale_date" label="ئوقۇتقۇچى بولغان ۋاقتى"></el-table-column>
          <el-table-column prop="courses_profit" label="دەرس ساتقان پايدىسى"></el-table-column>
          <el-table-column prop="vips_profit" label="VIPساتقان پايدىسى"></el-table-column>
          <el-table-column prop="platform_Profit" label="دەرسخانا پايدىسى"></el-table-column>
          <el-table-column prop="Withdrawal" label="نەقلەشتۈرگەن سومما"></el-table-column>
          <el-table-column prop="balance" label="نەقلەشتۈرمىگەن سومما"></el-table-column>
        </el-table>
        <!-- <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          background
          :current-page="current_page"
          :page-sizes="[10, 20, 30, 40, 50, 100]"
          layout="sizes,prev, pager, next,total,jumper"
          :total="total">
        </el-pagination> --> 
      </div>

      <el-row style="padding: 40px 0;">
        <el-col :span="24">
          <div class="rtl-right total-box">
            <p style="color: #409EFF;">
              <label>دەرس ساتقان پايدىسى</label>
              <span>{{totalData.courses_profit}}</span>
            </p>
            <p style="color: #67C23A;">
              <label>VIPساتقان پايدىسى</label>
              <span>{{totalData.vips_profit}}</span>
            </p>
            <p style="color: #67C23A;">
              <label>دەرسخانا پايدىسى</label>
              <span>{{totalData.platform_profit}}</span>
            </p>
            <p style="color: #67C23A;">
              <label>نەقلەشتۈرگەن سومما</label>
              <span>{{totalData.Withdrawal}}</span>
            </p>
            <p style="color: #67C23A;">
              <label>نەقلەشتۈرمىگەن سومما</label>
              <span>{{totalData.balance}}</span>
            </p>
          </div>
        </el-col>
      </el-row>

    </div>
  </div>
</template>

<script>
  var self;
  
  export default {
    activated: function() {
      self = this;
      document.querySelector("a[href='/statistics']").className = 'active-menu';
      self.getList();
      self.getTeachersList();
      self.getTotalData();
    },
    data() {
      return {
        tableData: [],
        loading: false,
        // per_page:10,
        // current_page:1,
        // last_page:0,
        // total:0,
        pageTags:{},
        filterForm:{
          teacher_id:null,
          keyword:null,
          timeRange:[]
        },
        teachersData:[],
        totalData:{}
      };
    },
    methods: {
      getList() {
        self.loading = true;
        self.$fetch("admin/statistic/user-teachers",{
          teacher_id:self.filterForm.teacher_id,
          start_date:self.filterForm.timeRange?self.filterForm.timeRange[0]:null,
          end_date:self.filterForm.timeRange?self.filterForm.timeRange[1]:null,
          keyword:self.filterForm.keyword,
          // page:self.current_page,
          // per_page:self.per_page
        }).then(response => {
          if (response.status == 200) {
            self.tableData = response.data.data;
            // self.total = response.data.meta.total;
            // self.current_page = response.data.meta.current_page;
            // self.last_page = response.data.meta.last_page;
          } else{
            console.log(response.message);
          }

          self.loading = false;
          if(JSON.parse(sessionStorage.getItem("curTags"))){
            self.pageTags = JSON.parse(sessionStorage.getItem("curTags"));
          }
        }).catch(err => {
          console.log('××××××××××××××');
          console.log(err);
          self.loading = false;
        });
      },
      // handleSizeChange(val) {
      //   self.per_page = val;
      //   self.getList();
      // },
      // handleCurrentChange(val) {
      //   self.current_page = val;
      //   self.getList();
      // },
      dateTimeChage(){
        self.toSearch();
      },
      toSearch(){
        self.current_page = 1;
        self.getList();
        self.getTotalData();
      },
      toClear(){
        self.filterForm = {
          teacher_id:null,
          keyword:null,
          timeRange:[]
        }
        self.toSearch();
      },
      getTeachersList(){
        self.$fetch("admin/teacher").then(response => {
          if (response.status == 200) {
           self.teachersData = response.data.data;
          } else{
            console.log(response.message);
          }
        }).catch(err => {
          console.log('××××××××××××××');
          console.log(err);
        });
      },
      getTotalData(){
        self.$fetch("admin/statistic/user-teachers",{
          type:1,
          teacher_id:self.filterForm.teacher_id,
          start_date:self.filterForm.timeRange?self.filterForm.timeRange[0]:null,
          end_date:self.filterForm.timeRange?self.filterForm.timeRange[1]:null,
          keyword:self.filterForm.keyword
        }).then(response => {
          if (response.status == 200) {
            self.totalData = response.data.data;
          } else{
            console.log(response.message);
          }
        }).catch(err => {
          console.log('××××××××××××××');
          console.log(err);
        });
      },
      indexMethod(index) {
        // return index + ((self.current_page-1) * self.per_page) + 1;
        return index + 1;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .search-box{
    .el-select,.el-input,.el-date-editor{
      width: 100%;
    }
    [class*="el-col-"]{
      float: right;
    }
  }
  .total-box{
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    p{
      display: flex;
      display: -webkit-flex;
      flex-direction: row;
      label{
        font-size: 18px;
        white-space: nowrap;
        margin-left: 20px;
        &:after{
          content: ':';
        }
      }
      span{
        font-size: 20px;
      }
      &:not(:last-child){
        margin-left: 80px;
      }
    }
  }
</style>